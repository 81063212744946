<div class="call-container">
  <div class="ctrl">
    <app-call-ctrl
      (callEnded)="callEndedClicked()"
      (modeChanged)="modeChanged($event)"
      (onClear)="clearCanvas()"
      (takeScreenshot)="takeScreenshot()"
      (recordLiveImage)="recordLiveImage()"
      [pictureLoading]="isPictureInProgress"
      [callEndedAt]="
        (videoChatService.activeRoom$ | async)?.data?.roomFinishedAt
      "
      [callStartedAt]="
        (videoChatService.activeRoom$ | async)?.data?.roomScheduledFor
      "
      [mode$]="callCtrlService.modeChannel$.asObservable()"
      [recordingAllowed]="
        (videoChatService.activeRoom$ | async)?.data?.recordingAllowed
      "
      [livePhotoAllowed]="
        (videoChatService.activeRoom$ | async)?.data?.livePhotoAllowed
      "
    ></app-call-ctrl>
  </div>
  <as-split [gutterStep]="40" direction="horizontal">
    <as-split-area [size]="60">
      <div class="content">
        <div class="content-container">
          <ng-container [ngTemplateOutlet]="videoContainer"></ng-container>
          <ng-container
            *ngIf="
              (videochatContentService.onCurrentContent() | async) ===
              ContentType.ATTACHMENT
            "
            [ngTemplateOutlet]="attachmentContainer"
          ></ng-container>
        </div>
        <div class="user-thumbnail">
          <app-camera #camera></app-camera>
        </div>
        <app-new-attachment-preview
          [slideInDirection]="slideInDirection"
        ></app-new-attachment-preview>
        <div class="center">
          <app-live-image-in-progress-overlay
            #liveImageOverlay
          ></app-live-image-in-progress-overlay>
        </div>
      </div>
    </as-split-area>
    <as-split-area [maxSize]="50" [size]="40">
      <div class="side">
        <app-side-bar
          [inquiryId]="inquiryId"
          [liveChatEnabled]="
            (videoChatService.activeRoom$ | async)?.data
              ?.liveChatTranslationsEnabled
          "
        ></app-side-bar>
      </div>
    </as-split-area>
  </as-split>
</div>

<ng-template #videoContainer>
  <div class="video-container">
    <div class="video-size-wrapper" #sizeWrapper>
      <app-picture-overlay
        id="picture-overlay"
        #screenshotOverlay
      ></app-picture-overlay>
      <app-drawing-canvas
        #drawingCanvas
        [canvasSyncService]="canvasSyncService"
        (drawn)="drawOnVideo($event)"
        [externalDrawEvent$]="videoChatService.userDrawEvent$"
        [mode$]="callCtrlService.modeChannel$.asObservable()"
      ></app-drawing-canvas>
      <app-participants
        id="participants"
        #participants
        class="canvas-overlay"
      ></app-participants>
    </div>
  </div>
</ng-template>

<ng-template #attachmentContainer>
  <div class="attachment-container">
    <app-present-image
      *ngIf="currentAttachment.type === AttachmentType.Image"
      [allowedControls]="allowedImageCtrls"
      (closeAttachment)="closeAttachment()"
      (modeChanged)="modeChanged($event)"
      [currentImage]="currentAttachment"
      [mode$]="callCtrlService.modeChannel$.asObservable()"
      class="canvas-overlay"
    >
    </app-present-image>
    <app-present-live-image
      *ngIf="currentAttachment.type === AttachmentType.LivePhoto"
      (closeAttachment)="closeAttachment()"
      [currentFile]="currentAttachment"
    >
    </app-present-live-image>
  </div>
</ng-template>
