import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-add-attachment-button',
  templateUrl: './add-attachment-button.component.html',
  styleUrls: ['./add-attachment-button.component.scss'],
})
export class AddAttachmentButtonComponent {
  @Output() filesSelected = new EventEmitter<FileList>();

  private readonly hiddenInput: HTMLInputElement;

  constructor() {
    this.hiddenInput = document.createElement('input');
    this.hiddenInput.type = 'file';
    this.hiddenInput.setAttribute('multiple', 'true');
    this.hiddenInput.onchange = (event: any) => {
      this.filesSelected.emit(event.target.files);
    };
  }

  selectFiles(): void {
    this.hiddenInput.click();
  }
}
