<div class="call-container">
  <div class="controls">
    <app-portrait-call-ctrl
      (takeScreenshot)="takeScreenshot()"
      [pictureLoading]="isPictureInProgress"
      (callEnded)="endCall()"
    ></app-portrait-call-ctrl>
  </div>
  <div class="content">
    <div class="content-container">
      <ng-container
        *ngIf="
          (videochatContentService.onCurrentContent() | async) ===
          ContentType.ATTACHMENT
        "
        [ngTemplateOutlet]="attachmentContainer"
      ></ng-container>
      <ng-container [ngTemplateOutlet]="videoContainer"></ng-container>
    </div>
    <app-new-attachment-preview
      [slideInDirection]="slideInDirection"
    ></app-new-attachment-preview>
    <div
      class="user-thumbnail"
      [style.display]="
        (videochatContentService.onCurrentContent() | async) ===
        ContentType.ATTACHMENT
          ? 'none'
          : 'block'
      "
    >
      <app-camera #camera [size]="'7rem'"></app-camera>
    </div>
  </div>
</div>

<ng-template #videoContainer>
  <div class="video-container">
    <div class="video-size-wrapper" #sizeWrapper>
      <app-picture-overlay
        id="picture-overlay"
        #screenshotOverlay
      ></app-picture-overlay>
      <app-move-hand-overlay *ngIf="showMovingHand"></app-move-hand-overlay>
      <app-drawing-canvas
        #drawingCanvas
        [canvasSyncService]="canvasSyncService"
        (drawn)="drawOnVideo($event)"
        [externalDrawEvent$]="videoChatService.userDrawEvent$"
        [mode$]="callCtrlService.modeChannel$.asObservable()"
      ></app-drawing-canvas>
      <app-participants
        id="participants"
        #participants
        class="canvas-overlay"
        (videoAvailable)="changeMovingHand($event)"
      ></app-participants>
    </div>
  </div>
</ng-template>

<ng-template #attachmentContainer>
  <div class="attachment-container">
    <app-present-image
      [allowedControls]="allowedImageCtrls"
      (closeAttachment)="closeAttachment()"
      [currentImage]="currentImage"
      [mode$]="callCtrlService.modeChannel$.asObservable()"
      class="canvas-overlay"
    >
    </app-present-image>
  </div>
</ng-template>
