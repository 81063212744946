<div
  class="container"
  [@enterAnimation]="slideInState"
  (@enterAnimation.start)="animationStart($event)"
  (@enterAnimation.done)="animationDone($event)"
  (click)="clicked()"
>
  <img
    *ngIf="src && type === AttachmentContentType.Image"
    class="preview"
    [src]="src"
  />
  <video
    *ngIf="src && type === AttachmentContentType.LivePhoto"
    class="preview"
    [src]="src"
    autoplay
    loop
    muted
  ></video>
  <div
    *ngIf="
      !src ||
      type === AttachmentContentType.Pdf ||
      type === AttachmentContentType.None
    "
    class="placeholder"
  >
    <img src="assets/images/description.svg" class="preview" />
  </div>
</div>
