<div class="img-wrap">
  <div>
    <mat-icon
      *ngIf="attachment.state === AttachmentState.UPLOADING"
      class="state uploading"
      >sync
    </mat-icon>
    <mat-icon
      *ngIf="attachment.state === AttachmentState.SUCCESSFUL"
      class="state success"
      >check
    </mat-icon>
    <mat-icon
      *ngIf="attachment.state === AttachmentState.FAILURE"
      class="state failure"
      >error
    </mat-icon>
  </div>
  @if (attachment?.attachment?.type === AttachmentContentType.Image) {
    <img
      class="thumbnail"
      src="{{
        attachment?.attachment?.previewSource
          ? attachment?.attachment?.previewSource
          : 'assets/images/description.svg'
      }}"
    />
  }
  @if (attachment?.attachment?.type === AttachmentContentType.LivePhoto) {
    <video
      class="thumbnail"
      [src]="attachment?.attachment?.blobUrl"
      loop
      autoplay
      muted
    ></video>
  }
  @if (
    attachment?.attachment?.type === AttachmentContentType.Pdf ||
    attachment?.attachment?.type === AttachmentContentType.None
  ) {
    <img class="thumbnail" src="assets/images/description.svg" />
  }
</div>
