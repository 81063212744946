import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  filter,
  first,
  firstValueFrom,
  map,
  mergeMap,
  Observable,
  of,
} from 'rxjs';
import {
  AttachmentDto,
  Author,
  Origin,
  SyncAppointmentService,
} from '../../../api/gen';
import { VideoChatService } from './videochat.service';
import { pairwise } from 'rxjs/operators';
import { AppointmentService } from '../../services/api/appointment.service';
import { AssociatedCallPeriod } from '../../model/attachment/associated-call-period';
import { AttachmentWithState } from '../../scheduling/inquiry-details/attachments/data/attachment-with-state';
import { AttachmentFileService } from '../../services/file/attachment-file.service';
import {
  AttachmentContentType,
  AttachmentContentTypeUtils,
} from '../../model/attachment/attachment-content-type';
import { AttachmentState } from '../../scheduling/inquiry-details/attachments/data/attachment-state';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AppointmentAttachmentsService {
  // null represents the loading state and the transition state between two inquiries
  public attachments$ = new BehaviorSubject<CallAttachment[] | null>(null);

  public pendingAttachments$ = new BehaviorSubject<
    AttachmentWithState[] | null
  >(null);

  public newAttachment$ = new Observable<CallAttachment>();

  private readonly currentAttachment$ =
    new BehaviorSubject<CurrentAttachmentData | null>(null);
  private currentInquiryId: string | undefined;

  constructor(
    private readonly appointmentService: AppointmentService,
    private readonly syncRepo: SyncAppointmentService,
    private readonly videoChatService: VideoChatService,
    private readonly attachmentFileService: AttachmentFileService,
  ) {
    this.videoChatService.activeRoom$.subscribe((room) => {
      if (room?.data?.inquiryIdentifier && !this.currentInquiryId) {
        this.refreshAttachmentsForAppointment(
          room?.data?.inquiryIdentifier,
        ).then();
      }
      this.currentInquiryId = room?.data?.inquiryIdentifier;

      if (!this.currentInquiryId) {
        this.attachments$.next(null);
        this.currentAttachment$.next(null);
      }
    });

    this.newAttachment$ = this.attachments$.pipe(
      pairwise(),
      map((x) => {
        const oldFiles = x[0];
        const newFiles = x[1];

        if (oldFiles == null || newFiles == null) return null;

        return newFiles.find(
          (newFile) =>
            oldFiles.findIndex((oldFile) => oldFile.id === newFile.id) == -1,
        );
      }),
      filter((x) => !!x),
    );
  }

  // TODO: This should not be here, as we also need to know about recording
  public checkIfInPresentationMode(
    inquiryId: string,
  ): Observable<CallAttachment | null> {
    return this.syncRepo
      .appointmentsRoomInquiryIdSyncGet(inquiryId)
      .pipe(
        map((x) =>
          x?.presentedAttachment
            ? CallAttachment.castFromDto(x.presentedAttachment)
            : null,
        ),
      );
  }

  // TODO: we should uniform this somehow with external events coming from the sync service
  // Actually,
  public async refreshAttachmentsForAppointment(inquiryId: string = null) {
    inquiryId ??= this.currentInquiryId;
    if (!inquiryId) return;
    const value = await firstValueFrom(
      this.appointmentService
        .getAttachments(inquiryId, true)
        .pipe(map((x) => x.flatMap(CallAttachment.castFromDto))),
    );
    this.attachments$.next(value);
  }

  public async updateAttachmentsWithData(data: AttachmentDto[]) {
    const result = data.flatMap(CallAttachment.castFromDto);
    this.attachments$.next(result);
  }

  public async deleteAttachment(attachmentId: string) {
    await firstValueFrom(
      this.appointmentService.deleteAttachment(
        this.currentInquiryId,
        attachmentId,
      ),
    );
  }

  public startPresentingAttachment(
    attachment: string | undefined = null,
  ): Observable<void> {
    if (!attachment) {
      // we should not use .value here
      const data = this.currentAttachment$.value;
      if (!data?.attachments[data?.currentIndex]?.id) {
        return of();
      }
      attachment = data.attachments[data.currentIndex]?.id;
    }
    this.switchPresentation(true);
    return this.appointmentService.startPresentingAttachment(
      this.currentInquiryId,
      attachment,
    );
  }

  public stopPresentingAttachment(): Observable<void> {
    this.switchPresentation(false);
    return this.appointmentService.stopPresentingAttachment(
      this.currentInquiryId,
    );
  }

  public changeCurrentAttachment(attachment: CurrentAttachmentData): void {
    this.currentAttachment$.next(attachment);
  }

  public onCurrentAttachment(): Observable<CurrentAttachmentData | null> {
    return this.currentAttachment$.asObservable();
  }

  public getCurrentAttachmentData(): CurrentAttachmentData | null {
    return this.currentAttachment$.value;
  }

  private switchPresentation(isPresented: boolean): void {
    const current = this.currentAttachment$.value;
    if (!current || current.isInPresentationMode == isPresented) return;
    current.isInPresentationMode = isPresented;
    this.currentAttachment$.next(current);
  }

  public uploadBlob(blob: Blob, fileName: string) {
    const file = new File([blob], fileName, { type: blob.type });
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    const fileList = dataTransfer.files;
    this.uploadFiles(fileList);
  }

  public uploadFiles(allFiles: FileList) {
    this.videoChatService.activeRoom$
      .pipe(
        first(),
        map((x) => x?.data?.inquiryIdentifier),
        filter((x) => !!x),
        mergeMap((x) => {
          return this.attachmentFileService.uploadAttachments(
            AssociatedCallPeriod.During,
            x,
            Array.from(allFiles),
          );
        }),
      )
      .subscribe((data) => {
        if (data.state == AttachmentState.SUCCESSFUL) {
          this.refreshAttachmentsForAppointment();
        }

        this.refreshPendingAttachments(data);
      });
  }

  private refreshPendingAttachments(data: AttachmentWithState) {
    let currentAttachments = this.pendingAttachments$.value;

    if (
      currentAttachments?.find((d) => d.name === data.name) &&
      data.state === AttachmentState.SUCCESSFUL
    ) {
      currentAttachments = currentAttachments.filter(
        (attachment) => attachment.name !== data.name,
      );
    } else if (currentAttachments) {
      currentAttachments.push(data);
    } else {
      currentAttachments = [data];
    }
    this.pendingAttachments$.next(currentAttachments);
  }

  public static generateLivePhotoFileName(): string {
    const date = formatDate(new Date(), 'hh_mm_ss', 'en_US');
    return 'LivePhoto' + date;
  }
}

export class CallAttachment {
  id: string;
  path: string | undefined;
  thumbnail_path: string | undefined;
  annotated_path: string | undefined;
  annotated_thumbnail_path: string | undefined;
  name: string | undefined;
  note?: string | null;
  extension: string;
  createdAt: Date;
  contentType: string | null;
  type: AttachmentContentType;
  processPercent: number | undefined;
  isInProcess: boolean;
  origin: AttachmentOrigin;
  author: AttachmentAuthor;

  static castFromDto(y: AttachmentDto): CallAttachment {
    const result = {
      id: y.attachmentIdentifier,
      path: y.blobUrl,
      thumbnail_path: y.thumbnailUrl,
      annotated_path: y.annotationBlobUrl,
      annotated_thumbnail_path: y.annotationThumbnailUrl,
      name: y.fileName,
      note: y.note,
      extension: y.extension,
      createdAt: new Date(y.createdAt),
      contentType: y.contentType,
      type: AttachmentContentTypeUtils.fromContentType(y.contentType),
      processPercent: y.processPercent,
      isInProcess: y.isInProcess,
      origin:
        y.origin === Origin.Recorded
          ? AttachmentOrigin.Recorded
          : AttachmentOrigin.Uploaded,
      author:
        y.author === Author.Customer
          ? AttachmentAuthor.Customer
          : AttachmentAuthor.Helper,
    } as CallAttachment;
    if (result.type === AttachmentContentType.LivePhoto) {
      result.thumbnail_path = result.path;
    }
    return result;
  }
}

export interface CurrentAttachmentData {
  attachments: CallAttachment[];
  currentIndex: number;
  canBePresented: boolean;
  isInPresentationMode: boolean;
}

export enum AttachmentOrigin {
  Uploaded = 0,
  Recorded = 1,
}

export enum AttachmentAuthor {
  Helper = 0,
  Customer = 1,
}
