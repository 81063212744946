import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { AttachmentContentType } from '../../model/attachment/attachment-content-type';

@Component({
  selector: 'app-attachment-list-card',
  templateUrl: './attachment-list-card.component.html',
  styleUrls: ['./attachment-list-card.component.scss'],
})
export class AttachmentListCardComponent {
  @Input()
  title: string;
  @Input()
  previewUrl: string;
  @Input()
  createdAt: string;
  @Input()
  extension: string;
  @Input()
  type: AttachmentContentType;
  @Input()
  noteAvailable: boolean;
  @Input() menu: MatMenu;

  @Input()
  clickable: boolean;

  @Output()
  onclick: EventEmitter<void> = new EventEmitter();

  @Output()
  onEditNote: EventEmitter<void> = new EventEmitter();

  AttachmentContentType = AttachmentContentType;

  constructor() {}

  onItemClicked(): void {
    this.onclick.emit();
  }

  onEditNoteClicked(): void {
    this.onEditNote.emit();
  }
}
