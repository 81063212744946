import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AttachmentListCardComponent } from './attachment-list-card/attachment-list-card.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { EditBasicInquiryInfoDialogComponent } from './dialogs/edit-basic-inquiry-info-dialog/edit-basic-inquiry-info-dialog.component';
import { DropzoneDirective } from './dropzone.directive';
import { IsOutsideDirective } from './phone-number-input/is-outside.directive';
import {
  OnlyNumberAndSpaceDirective,
  OnlyNumberDirective,
} from './phone-number-input/only-number.directive';
import { PhoneNumberInputComponent } from './phone-number-input/phone-number-input.component';
import { ScrollIntoViewDirective } from './phone-number-input/scroll-into-view.directive';
import { TabChipComponent } from './tab-chip/tab-chip.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditorComponent, EditorModule } from '@tinymce/tinymce-angular';
import { CardHeaderWithActionComponent } from './card-header-with-action/card-header-with-action.component';
import { ItemColumnDisplayComponent } from './item-column-display/item-column-display.component';
import { HeadingWithIconAndActionComponent } from './heading-with-icon-and-action/heading-with-icon-and-action.component';
import { DistanceInputComponent } from './distance-input/distance-input.component';
import { CallNotesComponent } from './call-notes/call-notes.component';
import { InfoTextWithIconComponent } from './info-text-with-icon/info-text-with-icon.component';
import { HeadingWithIconAndActionMenuComponent } from './heading-with-icon-and-action-menu/heading-with-icon-and-action-menu.component';
import { MatBadgeModule } from '@angular/material/badge';
import { EditAttachmentNoteDialogComponent } from './dialogs/edit-attachment-note-dialog/edit-attachment-note-dialog.component';
import { DeleteAttachmentDialogComponent } from './dialogs/delete-attachment-dialog/delete-attachment-dialog.component';
import { SafePipe } from './pipes/safe.pipe';
import { CopyInquiryLinkComponent } from './join-with-link/copy-inquiry-link.component';

@NgModule({
  declarations: [
    BackButtonComponent,
    PhoneNumberInputComponent,
    ScrollIntoViewDirective,
    OnlyNumberDirective,
    OnlyNumberAndSpaceDirective,
    IsOutsideDirective,
    TabChipComponent,
    DropzoneDirective,
    AttachmentListCardComponent,
    EditBasicInquiryInfoDialogComponent,
    CardHeaderWithActionComponent,
    ItemColumnDisplayComponent,
    HeadingWithIconAndActionComponent,
    HeadingWithIconAndActionMenuComponent,
    DistanceInputComponent,
    CallNotesComponent,
    InfoTextWithIconComponent,
    EditAttachmentNoteDialogComponent,
    DeleteAttachmentDialogComponent,
    SafePipe,
    CopyInquiryLinkComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    EditorModule,
    FormsModule,
    MatProgressBarModule,
    OverlayModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCardModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatMenuModule,
    MatTooltipModule,
    MatBadgeModule,
  ],
  exports: [
    BackButtonComponent,
    PhoneNumberInputComponent,
    ScrollIntoViewDirective,
    OnlyNumberDirective,
    OnlyNumberAndSpaceDirective,
    IsOutsideDirective,
    TabChipComponent,
    DropzoneDirective,
    AttachmentListCardComponent,
    EditBasicInquiryInfoDialogComponent,
    EditorComponent,
    CardHeaderWithActionComponent,
    ItemColumnDisplayComponent,
    HeadingWithIconAndActionComponent,
    DistanceInputComponent,
    CallNotesComponent,
    InfoTextWithIconComponent,
    HeadingWithIconAndActionMenuComponent,
    SafePipe,
    CopyInquiryLinkComponent,
  ],
})
export class SharedModule {}
