<div class="container">
  <div
    #attachmentContainer
    (onFileDropped)="uploadFiles($event)"
    [scrollTop]="attachmentContainer.scrollHeight"
    appDropzone
    class="attachment-container"
  >
    <div
      *ngFor="let x of attachments; trackBy: trackImageBy"
      class="attachment"
    >
      <ng-container
        [ngTemplateOutletContext]="{ data: x }"
        [ngTemplateOutlet]="attachment"
      ></ng-container>
    </div>
    <div *ngIf="attachments?.length === 0" class="attachments-placeholder">
      <mat-icon class="dad-icon"> upload-file</mat-icon>
      <div class="title" i18n>
        Dateien hier ablegen oder aus der Zwischenablage einfügen
      </div>
      <div class="description" i18n>
        Dateien können präsentiert werden und stehen auch dem blankmile Call zur
        Verfügung.
      </div>
    </div>
  </div>
  <div *ngIf="uploadingAttachments.length > 0" class="pending-attachments">
    <span i18n
      >Lade
      {uploadingAttachments.length, plural,
        =1 {Anhang}
        other {{{ uploadingAttachments.length }} Anhänge}
      }
      hoch...</span
    >
    <mat-spinner diameter="20"></mat-spinner>
  </div>
  <div>
    <app-add-attachment-button
      (filesSelected)="uploadFiles($event)"
    ></app-add-attachment-button>
  </div>
</div>

<ng-template #attachment let-data="data">
  <app-attachment-list-card
    [title]="data.name"
    [extension]="data.extension"
    [createdAt]="data.createdAt"
    [type]="data.type"
    [noteAvailable]="data.note !== null"
    [previewUrl]="data.annotated_thumbnail_path || data.thumbnail_path"
    [clickable]="
      data.type === AttachmentContentType.Image ||
      data.type === AttachmentContentType.LivePhoto
    "
    [menu]="menu"
    (onclick)="openAttachment(data)"
    (onEditNote)="editAttachmentNotes(data.id)"
  >
  </app-attachment-list-card>
  <mat-menu #menu="matMenu">
    <button
      *ngIf="
        data.type === AttachmentContentType.Image ||
        data.type === AttachmentContentType.LivePhoto
      "
      (click)="openAttachment(data)"
      mat-menu-item
    >
      <mat-icon>visibility</mat-icon>
      <span i18n>Anzeigen</span>
    </button>
    <button (click)="deleteAttachment(data.id)" mat-menu-item>
      <mat-icon>delete</mat-icon>
      <span i18n>Löschen</span>
    </button>
    <button (click)="editAttachmentNotes(data.id)" mat-menu-item>
      <mat-icon>description</mat-icon>
      <span i18n>Notiz bearbeiten</span>
    </button>
  </mat-menu>
</ng-template>
